#accountNavContainer {
  background-color: #9dc5c3;
  background-image: linear-gradient(315deg, #9dc5c3 0%, #5e5c5c 74%);
  height: 75px;
  width: 100%;
  position: absolute;
}

#subContainer {
  height: 82.5vh;
}

#subInfo {
  top: 125px;
}

/* subContainer responsive styling */

@media (min-width: 1366px) and (max-width: 1440px) {
  #subContainer {
    height: 86vh;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  #subContainer {
    height: 84.5vh;
  }
}

@media (min-width: 2560px) and (max-width: 3440px) {
  #subContainer {
    height: 80.5vh;
  }
}

@media (min-width: 3840px) {
  #subContainer {
    height: 78vh;
  }
}

/* AccountDropdown responsive styling */

#accountDropdown {
  left: 722px;
  top: 125px;
}

@media (min-width: 1920px) and (max-width: 2559px) {
  #accountDropdown {
    left: 1045px;
    top: 125px;
  }
}

@media (min-width: 1536px) and (max-width: 1599px) {
  #accountDropdown {
    left: 787px;
    top: 125px;
  }
}

@media (min-width: 1600px) and (max-width: 1679px) {
  #accountDropdown {
    left: 830px;
    top: 125px;
  }
}

@media (min-width: 1680px) and (max-width: 1919px) {
  #accountDropdown {
    left: 882px;
    top: 125px;
  }
}

@media (min-width: 2560px) and (max-width: 3439px) {
  #accountDropdown {
    left: 1468px;
    top: 125px;
  }
}

@media (min-width: 3440px) and (max-width: 3839px) {
  #accountDropdown {
    left: 2058px;
    top: 125px;
  }
}

@media (min-width: 3840px) {
  #accountDropdown {
    left: 2323px;
    top: 125px;
  }
}

/* Table Row responsive styling */

@media (min-width: 2560px) and (max-width: 3840px) {
  .tableRow {
    width: 500px;
  }
}

/* Mobile responsive design */

@media (max-width: 500px) {
  #accountNavContainer {
    display: none;
  }
}

@media (max-width: 500px) {
  #SelectFundBtn {
    display: none;
  }
}

@media (min-width: 500px) {
  #metricsChartContainer {
    height: 37vh;
    width: 31.5vw;
  }
}

@media (min-width: 500px) {
  #barChartContainer {
    height: 37vh;
    width: 31.5vw;
  }
}

@media (min-width: 500px) {
  #volGraphContainer {
    height: 37vh;
    width: 63.5vw;
  }
}
