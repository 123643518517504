.videoContainer {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  width: 100%;
  padding: 0;
}

@media (min-width: 1921px) {
  .videoContainer {
    background-image: url(https://res.cloudinary.com/fabien14/image/upload/v1625479262/Ryo-Bridge/joseph-barrientos-fqmrAEiye1M-unsplash_mrqjiv.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 950px;
  }
  #video {
    opacity: 0;
  }
}

@media (max-width: 500px) {
  .videoContainer {
    display: none;
  }
}

@media (min-width: 1920px) {
  #video {
    min-height: 960px;
  }
}

@media (min-width: 1680px) and (max-width: 1919px) {
  #video {
    min-height: 940px;
  }
}

@media (min-width: 1440px) and (max-width: 1679px) {
  #video {
    min-height: 800px;
  }
}

#video {
  max-height: 650px;
  min-width: 100%;
  object-fit: fill;
}
