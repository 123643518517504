@tailwind base;
@tailwind components;
@tailwind utilities;

/* Home styling */

@media (min-width: 2560px) {
  #liveFundDataContainer {
    width: 40%;
  }
}

@media (max-width: 500px) {
  #homeTitle {
    display: none;
  }
}

/* Strategy styling */

#masterFundGraphTitle {
  display: none;
}
#iFundGraphTitle {
  display: none;
}

@media (min-width: 1921px) {
  #iFundStrategyText {
    margin-left: 10%;
  }

  #iFundStrategy {
    margin-left: 10%;
  }
}

@media (max-width: 500px) {
  #masterFundGraphTitle {
    display: block;
  }
  #iFundGraphTitle {
    display: block;
  }
}

/* SignUp and LoginIn styling */

#accessAccountBackgroundContainer {
  background-image: url(https://res.cloudinary.com/fabien14/image/upload/v1627047383/Ryo-Bridge/pietro-jeng-n6B49lTx7NM-unsplash_z76vj9.jpg);
  background-position: center;
  background-size: cover;
  width: calc(100% - 500px);
}

#accessAccountContainer {
  width: 100%;
}

#accessAccountLogoContainer {
  width: 100%;
}

@media (max-width: 768px) {
  #accessAccountBackgroundContainer {
    display: none;
  }
}

@media (min-width: 769px) {
  #accessAccountContainer {
    width: 500px;
  }
}
