.scroll {
  width: 100%;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.scroll div {
  animation: animate 20s linear infinite;
}

@media (max-width: 500px) {
  #fundTickerServerOnline {
    display: none;
  }
}

@keyframes animate {
  0% {
    transform: translateX(2%);
  }
  100% {
    transform: translateX(-102%);
  }
}

@media (min-width: 2560px) {
  @keyframes animate {
    0% {
      transform: translateX(1%);
    }
    100% {
      transform: translateX(-103%);
    }
  }
  #secondTickerBody {
    margin-left: 340px;
  }
}

@media (min-width: 3440px) {
  @keyframes animate {
    0% {
      transform: translateX(-4%);
    }
    100% {
      transform: translateX(-105%);
    }
  }
  #secondTickerBody {
    margin-left: 1780px;
  }
  .scroll div {
    animation: animate 30s linear infinite;
  }
}
